<template>
  <div style="font-size: 12px; min-width: 1600px;">
    <el-alert v-if="errMsg" :title="errMsg" type="error" center show-icon></el-alert>
    <el-row>
      <el-col :span="4">
        <div>
          <el-input v-model="initmatchId" placeholder="Please input">
            <template #append><el-button type="primary" @click="query">查询</el-button></template>
          </el-input>
        </div>
        <div>
          <el-button type="primary" @click="matchTotalClick" :icon="Football">数据库总场次:{{ matchTotal }}</el-button>
        </div>
        <el-date-picker v-model="date" type="date" @change="changDate"></el-date-picker>
        <table border="0" cellpadding="0" cellspacing="0" style="margin:auto">
          <tr v-for="(item, index) in goalmodelList" :key="index" @click="changeMatch(item)" :class="matchId === item.id?'selectedstyle':''">
            <td>{{index+1}}</td>
            <td>{{item.leagueAbbName}}</td>
            <td>{{item.homeTeamAbbName}}VS{{item.awayTeamAbbName}}</td>
          </tr>
        </table>
      </el-col>
      <el-col :span="8">
        <div style="text-align: center;font-size: 20px;">{{ initmatchId }} ---- {{singleField.homeTeamAbbName}}VS{{singleField.awayTeamAbbName}}</div>
        <table border=“1” cellpadding="0" cellspacing="0" style="font-weight:bold;">
          <tr style="background-color: gainsboro;">
            <td></td>
            <td>0</td>
            <td>1</td>
            <td>2</td>
            <td>3</td>
            <td>4</td>
            <td>5</td>
            <td>6</td>
            <td>7</td>
          </tr>
          <tr style="background-color: gainsboro;">
            <td>进球数初赔</td>
            <td>{{singleField.s0}}</td>
            <td>{{singleField.s1}}</td>
            <td>{{singleField.s2}}</td>
            <td>{{singleField.s3}}</td>
            <td>{{singleField.s4}}</td>
            <td>{{singleField.s5}}</td>
            <td>{{singleField.s6}}</td>
            <td>{{singleField.s7}}</td>
          </tr>
          <tr style="background-color: gainsboro;">
            <td>进球数即赔</td>
            <td>{{singleField.news0}}</td>
            <td>{{singleField.news1}}</td>
            <td>{{singleField.news2}}</td>
            <td>{{singleField.news3}}</td>
            <td>{{singleField.news4}}</td>
            <td>{{singleField.news5}}</td>
            <td>{{singleField.news6}}</td>
            <td>{{singleField.news7}}</td>
          </tr>
          <tr style="background-color: gainsboro;">
            <td>进球数初赔概率</td>
            <td>{{singleField.s0odds/10000}}%</td>
            <td>{{singleField.s1odds/10000}}%</td>
            <td>{{singleField.s2odds/10000}}%</td>
            <td>{{singleField.s3odds/10000}}%</td>
            <td>{{singleField.s4odds/10000}}%</td>
            <td>{{singleField.s5odds/10000}}%</td>
            <td>{{singleField.s6odds/10000}}%</td>
            <td>{{singleField.s7odds/10000}}%</td>
          </tr>
          <tr style="background-color: gainsboro;">
            <td>进球数即赔概率</td>
            <td>{{singleField.news0odds/10000}}%</td>
            <td>{{singleField.news1odds/10000}}%</td>
            <td>{{singleField.news2odds/10000}}%</td>
            <td>{{singleField.news3odds/10000}}%</td>
            <td>{{singleField.news4odds/10000}}%</td>
            <td>{{singleField.news5odds/10000}}%</td>
            <td>{{singleField.news6odds/10000}}%</td>
            <td>{{singleField.news7odds/10000}}%</td>
          </tr>
        </table>
        <table border=“1” cellpadding="0" cellspacing="0" style="font-weight:bold;">
          <tr style="background-color: gainsboro;">
            <td rowspan="2"></td>
            <td colspan="13">胜</td>
            <td colspan="5">平</td>
            <td colspan="13">负</td>
          </tr>
          <tr style="background-color: gainsboro;">
            <td>1:0</td>
            <td>2:0</td>
            <td>2:1</td>
            <td>3:0</td>
            <td>3:1</td>
            <td>3:2</td>
            <td>4:0</td>
            <td>4:1</td>
            <td>4:2</td>
            <td>5:0</td>
            <td>5:1</td>
            <td>5:2</td>
            <td>其他</td>
            <td>0:0</td>
            <td>1:1</td>
            <td>2:2</td>
            <td>3:3</td>
            <td>其他</td>
            <td>0:1</td>
            <td>0:2</td>
            <td>1:2</td>
            <td>0:3</td>
            <td>1:3</td>
            <td>2:3</td>
            <td>0:4</td>
            <td>1:4</td>
            <td>2:4</td>
            <td>0:5</td>
            <td>1:5</td>
            <td>2:5</td>
            <td>其他</td>
          </tr>
          <tr style="background-color: gainsboro;">
            <td>比分初赔</td>
            <td>{{singleField.s01s00}}</td>
            <td>{{singleField.s02s00}}</td>
            <td>{{singleField.s02s01}}</td>
            <td>{{singleField.s03s00}}</td>
            <td>{{singleField.s03s01}}</td>
            <td>{{singleField.s03s02}}</td>
            <td>{{singleField.s04s00}}</td>
            <td>{{singleField.s04s01}}</td>
            <td>{{singleField.s04s02}}</td>
            <td>{{singleField.s05s00}}</td>
            <td>{{singleField.s05s01}}</td>
            <td>{{singleField.s05s02}}</td>
            <td>{{singleField.s_1sh}}</td>
            <td>{{singleField.s00s00}}</td>
            <td>{{singleField.s01s01}}</td>
            <td>{{singleField.s02s02}}</td>
            <td>{{singleField.s03s03}}</td>
            <td>{{singleField.s_1sd}}</td>
            <td>{{singleField.s00s01}}</td>
            <td>{{singleField.s00s02}}</td>
            <td>{{singleField.s01s02}}</td>
            <td>{{singleField.s00s03}}</td>
            <td>{{singleField.s01s03}}</td>
            <td>{{singleField.s02s03}}</td>
            <td>{{singleField.s00s04}}</td>
            <td>{{singleField.s01s04}}</td>
            <td>{{singleField.s02s04}}</td>
            <td>{{singleField.s00s05}}</td>
            <td>{{singleField.s01s05}}</td>
            <td>{{singleField.s02s05}}</td>
            <td>{{singleField.s_1sa}}</td>
          </tr>
          <tr style="background-color: gainsboro;">
            <td>比分即赔</td>
            <td>{{singleField.news01s00}}</td>
            <td>{{singleField.news02s00}}</td>
            <td>{{singleField.news02s01}}</td>
            <td>{{singleField.news03s00}}</td>
            <td>{{singleField.news03s01}}</td>
            <td>{{singleField.news03s02}}</td>
            <td>{{singleField.news04s00}}</td>
            <td>{{singleField.news04s01}}</td>
            <td>{{singleField.news04s02}}</td>
            <td>{{singleField.news05s00}}</td>
            <td>{{singleField.news05s01}}</td>
            <td>{{singleField.news05s02}}</td>
            <td>{{singleField.news_1sh}}</td>
            <td>{{singleField.news00s00}}</td>
            <td>{{singleField.news01s01}}</td>
            <td>{{singleField.news02s02}}</td>
            <td>{{singleField.news03s03}}</td>
            <td>{{singleField.news_1sd}}</td>
            <td>{{singleField.news00s01}}</td>
            <td>{{singleField.news00s02}}</td>
            <td>{{singleField.news01s02}}</td>
            <td>{{singleField.news00s03}}</td>
            <td>{{singleField.news01s03}}</td>
            <td>{{singleField.news02s03}}</td>
            <td>{{singleField.news00s04}}</td>
            <td>{{singleField.news01s04}}</td>
            <td>{{singleField.news02s04}}</td>
            <td>{{singleField.news00s05}}</td>
            <td>{{singleField.news01s05}}</td>
            <td>{{singleField.news02s05}}</td>
            <td>{{singleField.news_1sa}}</td>
          </tr>
          <tr style="background-color: gainsboro;">
            <td>比分初赔概率</td>
            <td>{{singleField.s01s00odds / 100000}}%</td>
            <td>{{singleField.s02s00odds / 100000}}%</td>
            <td>{{singleField.s02s01odds / 100000}}%</td>
            <td>{{singleField.s03s00odds / 100000}}%</td>
            <td>{{singleField.s03s01odds / 100000}}%</td>
            <td>{{singleField.s03s02odds / 100000}}%</td>
            <td>{{singleField.s04s00odds / 100000}}%</td>
            <td>{{singleField.s04s01odds / 100000}}%</td>
            <td>{{singleField.s04s02odds / 100000}}%</td>
            <td>{{singleField.s05s00odds / 100000}}%</td>
            <td>{{singleField.s05s01odds / 100000}}%</td>
            <td>{{singleField.s05s02odds / 100000}}%</td>
            <td>{{singleField.s_1shodds / 100000}}%</td>
            <td>{{singleField.s00s00odds / 100000}}%</td>
            <td>{{singleField.s01s01odds / 100000}}%</td>
            <td>{{singleField.s02s02odds / 100000}}%</td>
            <td>{{singleField.s03s03odds / 100000}}%</td>
            <td>{{singleField.s_1sdodds / 100000}}%</td>
            <td>{{singleField.s00s01odds / 100000}}%</td>
            <td>{{singleField.s00s02odds / 100000}}%</td>
            <td>{{singleField.s01s02odds / 100000}}%</td>
            <td>{{singleField.s00s03odds / 100000}}%</td>
            <td>{{singleField.s01s03odds / 100000}}%</td>
            <td>{{singleField.s02s03odds / 100000}}%</td>
            <td>{{singleField.s00s04odds / 100000}}%</td>
            <td>{{singleField.s01s04odds / 100000}}%</td>
            <td>{{singleField.s02s04odds / 100000}}%</td>
            <td>{{singleField.s00s05odds / 100000}}%</td>
            <td>{{singleField.s01s05odds / 100000}}%</td>
            <td>{{singleField.s02s05odds / 100000}}%</td>
            <td>{{singleField.s_1saodds / 100000}}%</td>
          </tr>
          <tr style="background-color: gainsboro;">
            <td>比分即赔概率</td>
            <td>{{singleField.news01s00odds / 100000}}%</td>
            <td>{{singleField.news02s00odds / 100000}}%</td>
            <td>{{singleField.news02s01odds / 100000}}%</td>
            <td>{{singleField.news03s00odds / 100000}}%</td>
            <td>{{singleField.news03s01odds / 100000}}%</td>
            <td>{{singleField.news03s02odds / 100000}}%</td>
            <td>{{singleField.news04s00odds / 100000}}%</td>
            <td>{{singleField.news04s01odds / 100000}}%</td>
            <td>{{singleField.news04s02odds / 100000}}%</td>
            <td>{{singleField.news05s00odds / 100000}}%</td>
            <td>{{singleField.news05s01odds / 100000}}%</td>
            <td>{{singleField.news05s02odds / 100000}}%</td>
            <td>{{singleField.news_1shodds / 100000}}%</td>
            <td>{{singleField.news00s00odds / 100000}}%</td>
            <td>{{singleField.news01s01odds / 100000}}%</td>
            <td>{{singleField.news02s02odds / 100000}}%</td>
            <td>{{singleField.news03s03odds / 100000}}%</td>
            <td>{{singleField.news_1sdodds / 100000}}%</td>
            <td>{{singleField.news00s01odds / 100000}}%</td>
            <td>{{singleField.news00s02odds / 100000}}%</td>
            <td>{{singleField.news01s02odds / 100000}}%</td>
            <td>{{singleField.news00s03odds / 100000}}%</td>
            <td>{{singleField.news01s03odds / 100000}}%</td>
            <td>{{singleField.news02s03odds / 100000}}%</td>
            <td>{{singleField.news00s04odds / 100000}}%</td>
            <td>{{singleField.news01s04odds / 100000}}%</td>
            <td>{{singleField.news02s04odds / 100000}}%</td>
            <td>{{singleField.news00s05odds / 100000}}%</td>
            <td>{{singleField.news01s05odds / 100000}}%</td>
            <td>{{singleField.news02s05odds / 100000}}%</td>
            <td>{{singleField.news_1saodds / 100000}}%</td>
          </tr>
        </table>
        <table border=“1” cellpadding="0" cellspacing="0">
          <tr>
            <td></td>
            <td>场数</td>
            <td>0球</td>
            <td>1球</td>
            <td>2球</td>
            <td>3球</td>
            <td>4球</td>
            <td>5球</td>
            <td>6球</td>
            <td>7球</td>
          </tr>
          <tr v-if="tuliData['chupei10juncha']">
            <td>初赔10均差</td>
            <td>{{ tuliData.len10 }}</td>
            <td>{{ tuliData.chupei10juncha.s0odds }}</td>
            <td>{{ tuliData.chupei10juncha.s1odds }}</td>
            <td>{{ tuliData.chupei10juncha.s2odds }}</td>
            <td>{{ tuliData.chupei10juncha.s3odds }}</td>
            <td>{{ tuliData.chupei10juncha.s4odds }}</td>
            <td>{{ tuliData.chupei10juncha.s5odds }}</td>
            <td>{{ tuliData.chupei10juncha.s6odds }}</td>
            <td>{{ tuliData.chupei10juncha.s7odds }}</td>
          </tr>
          <tr v-if="tuliData['chupei01juncha']">
            <td>初赔01均差</td>
            <td>{{ tuliData.len01 }}</td>
            <td>{{ tuliData.chupei01juncha.s0odds }}</td>
            <td>{{ tuliData.chupei01juncha.s1odds }}</td>
            <td>{{ tuliData.chupei01juncha.s2odds }}</td>
            <td>{{ tuliData.chupei01juncha.s3odds }}</td>
            <td>{{ tuliData.chupei01juncha.s4odds }}</td>
            <td>{{ tuliData.chupei01juncha.s5odds }}</td>
            <td>{{ tuliData.chupei01juncha.s6odds }}</td>
            <td>{{ tuliData.chupei01juncha.s7odds }}</td>
          </tr>
        </table>
        <table border=“1” cellpadding="0" cellspacing="0">
          <tr>
            <th></th>
            <th>0球</th>
            <th>1球</th>
            <th>2球</th>
            <th>3球</th>
            <th>4球</th>
            <th>5球</th>
          </tr>
          <tr v-if="tuliData['chupei10juncha']">
            <th rowspan="2">0球</th>
            <td>{{ tuliData.chupei10juncha.s00s00odds }}</td>
            <td rowspan="2">{{ tuliData.chupei10juncha.s00s01odds }}</td>
            <td>{{ tuliData.chupei10juncha.s00s02odds }}</td>
            <td>{{ tuliData.chupei10juncha.s00s03odds }}</td>
            <td>{{ tuliData.chupei10juncha.s00s04odds }}</td>
            <td>{{ tuliData.chupei10juncha.s00s05odds }}</td>
          </tr>
          <tr v-if="tuliData['chupei01juncha']">
            <td>{{ tuliData.chupei01juncha.s00s00odds }}</td>
            <!--td>{{ tuliData.chupei01juncha.s00s01odds }}</td-->
            <td>{{ tuliData.chupei01juncha.s00s02odds }}</td>
            <td>{{ tuliData.chupei01juncha.s00s03odds }}</td>
            <td>{{ tuliData.chupei01juncha.s00s04odds }}</td>
            <td>{{ tuliData.chupei01juncha.s00s05odds }}</td>
          </tr>
          <tr v-if="tuliData['chupei10juncha']">
            <th rowspan="2">1球</th>
            <td rowspan="2">{{ tuliData.chupei10juncha.s01s00odds }}</td>
            <td>{{ tuliData.chupei10juncha.s01s01odds }}</td>
            <td>{{ tuliData.chupei10juncha.s01s02odds }}</td>
            <td>{{ tuliData.chupei10juncha.s01s03odds }}</td>
            <td>{{ tuliData.chupei10juncha.s01s04odds }}</td>
            <td>{{ tuliData.chupei10juncha.s01s05odds }}</td>
          </tr>
          <tr v-if="tuliData['chupei01juncha']">
            <!--td>{{ tuliData.chupei01juncha.s01s00odds }}</td-->
            <td>{{ tuliData.chupei01juncha.s01s01odds }}</td>
            <td>{{ tuliData.chupei01juncha.s01s02odds }}</td>
            <td>{{ tuliData.chupei01juncha.s01s03odds }}</td>
            <td>{{ tuliData.chupei01juncha.s01s04odds }}</td>
            <td>{{ tuliData.chupei01juncha.s01s05odds }}</td>
          </tr>
          <tr v-if="tuliData['chupei10juncha']">
            <th rowspan="2">2球</th>
            <td>{{ tuliData.chupei10juncha.s02s00odds }}</td>
            <td>{{ tuliData.chupei10juncha.s02s01odds }}</td>
            <td>{{ tuliData.chupei10juncha.s02s02odds }}</td>
            <td>{{ tuliData.chupei10juncha.s02s03odds }}</td>
            <td>{{ tuliData.chupei10juncha.s02s04odds }}</td>
            <td>{{ tuliData.chupei10juncha.s02s05odds }}</td>
          </tr>
          <tr v-if="tuliData['chupei01juncha']">
            <td>{{ tuliData.chupei01juncha.s02s00odds }}</td>
            <td>{{ tuliData.chupei01juncha.s02s01odds }}</td>
            <td>{{ tuliData.chupei01juncha.s02s02odds }}</td>
            <td>{{ tuliData.chupei01juncha.s02s03odds }}</td>
            <td>{{ tuliData.chupei01juncha.s02s04odds }}</td>
            <td>{{ tuliData.chupei01juncha.s02s05odds }}</td>
          </tr>
          <tr v-if="tuliData['chupei10juncha']">
            <th rowspan="2">3球</th>
            <td>{{ tuliData.chupei10juncha.s03s00odds }}</td>
            <td>{{ tuliData.chupei10juncha.s03s01odds }}</td>
            <td>{{ tuliData.chupei10juncha.s03s02odds }}</td>
            <td>{{ tuliData.chupei10juncha.s03s03odds }}</td>
            <td>-</td>
            <td>-</td>
          </tr>
          <tr v-if="tuliData['chupei01juncha']">
            <td>{{ tuliData.chupei01juncha.s03s00odds }}</td>
            <td>{{ tuliData.chupei01juncha.s03s01odds }}</td>
            <td>{{ tuliData.chupei01juncha.s03s02odds }}</td>
            <td>{{ tuliData.chupei01juncha.s03s03odds }}</td>
            <td>-</td>
            <td>-</td>
          </tr>
          <tr v-if="tuliData['chupei10juncha']">
            <th rowspan="2">4球</th>
            <td>{{ tuliData.chupei10juncha.s04s00odds }}</td>
            <td>{{ tuliData.chupei10juncha.s04s01odds }}</td>
            <td>{{ tuliData.chupei10juncha.s04s02odds }}</td>
            <td>-</td>
            <td>-</td>
            <td>{{ tuliData.chupei10juncha.s_1saodds }}</td>
          </tr>
          <tr v-if="tuliData['chupei01juncha']">
            <td>{{ tuliData.chupei01juncha.s04s00odds }}</td>
            <td>{{ tuliData.chupei01juncha.s04s01odds }}</td>
            <td>{{ tuliData.chupei01juncha.s04s02odds }}</td>
            <td>-</td>
            <td>-</td>
            <td>{{ tuliData.chupei01juncha.s_1saodds }}</td>
          </tr>
          <tr v-if="tuliData['chupei10juncha']">
            <th rowspan="2">5球</th>
            <td>{{ tuliData.chupei10juncha.s05s00odds }}</td>
            <td>{{ tuliData.chupei10juncha.s05s01odds }}</td>
            <td>{{ tuliData.chupei10juncha.s05s02odds }}</td>
            <td>-</td>
            <td>{{ tuliData.chupei10juncha.s_1shodds }}</td>
            <td>{{ tuliData.chupei10juncha.s_1sdodds }}</td>
          </tr>
          <tr v-if="tuliData['chupei01juncha']">
            <td>{{ tuliData.chupei01juncha.s05s00odds }}</td>
            <td>{{ tuliData.chupei01juncha.s05s01odds }}</td>
            <td>{{ tuliData.chupei01juncha.s05s02odds }}</td>
            <td>-</td>
            <td>{{ tuliData.chupei10juncha.s_1shodds }}</td>
            <td>{{ tuliData.chupei01juncha.s_1sdodds }}</td>
          </tr>
        </table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data () {
    return {
      matchTotal: 0,
      initmatchId: 115371,
      date: '',
      matchId: '',
      singleField: {},
      goalmodelList: [],
      errMsg: '',
      tuliData: {}
    }
  },
  mounted () {
    this.formatDate(new Date())
    this.getGoalmodelList()
  },
  methods: {
    towNumber (val) {
      return val.toFixed(2)
    },
    formatNumber (n) {
      n = n.toString()
      return n[1] ? n : '0' + n
    },
    formatDate (date) {
      var year = date.getFullYear()
      var month = date.getMonth() + 1
      var day = date.getDate()
      this.date = [year, month, day].map(this.formatNumber).join('-')
    },
    getGoalmodelList () {
      this.$axios.get('api/goalmodel/list', {
        params: {
          date: this.date
        }
      }).then(res => {
        console.log(res)
        const temp = res.data
        if (temp.errcode === -1) {
          this.$router.push({ path: '/login' })
        } else if (temp.errcode > 0) {
          this.errMsg = temp.msg
        } else if (temp.errcode === 0) {
          this.goalmodelList = temp.data
        }
      })
    },
    changDate (event) {
      this.formatDate(this.date)
      this.getGoalmodelList()
    },
    changeMatch (event) {
      this.matchId = event.id
      this.$axios.get('api/admin/zonghetuli/mate', {
        params: {
          matchId: this.matchId
        }
      }).then(res => {
        console.log(res.data)
        const temp = res.data
        if (temp.errcode === -1) {
          this.$router.push({ path: '/login' })
        } else if (temp.errcode > 0) {
          this.errMsg = temp.msg
        } else if (temp.errcode === 0) {
          this.tuliData = temp.data
        }
      })
    },
    query (event) {
      this.$axios.get('api/admin/zonghetuli/singleField', {
        params: {
          matchId: this.initmatchId
        }
      }).then(res => {
        console.log(res)
        const temp = res.data
        if (temp.errcode === -1) {
          this.$router.push({ path: '/login' })
        } else if (temp.errcode > 0) {
          this.errMsg = temp.msg
        } else if (temp.errcode === 0) {
          this.singleField = temp.data
          this.initmatchId += 1
        }
      })
    },
    matchTotalClick (event) {
      this.$axios.get('api/admin/zonghetuli/matchTotal', {
        params: {}
      }).then(res => {
        console.log(res)
        const temp = res.data
        if (temp.errcode === -1) {
          this.$router.push({ path: '/login' })
        } else if (temp.errcode > 0) {
          this.errMsg = temp.msg
        } else if (temp.errcode === 0) {
          this.matchTotal = temp.total
        }
      })
    }
  }
}
</script>

<style>
  td {
    padding: 10px 12px;
  }
  .tableSoccer {
    padding: 3px 5px;
  }
  .selectedstyle {
    background-color: yellowgreen;
  }
</style>
